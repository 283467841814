import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAqgUIsSDUFAzDhfThQ2Hso9ZEsDIz59EE",
  authDomain: "hfs-live-31172.firebaseapp.com",
  projectId: "hfs-live-31172",
  storageBucket: "hfs-live-31172.appspot.com",
  messagingSenderId: "996367262219",
  appId: "1:996367262219:web:d340ecb87ce760b1335d0e"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
  
// export { auth };
export default auth;
 