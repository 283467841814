import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { API_URL } from '../../config';
import { Edit2, EyeOff, Trash } from 'react-feather';
import { MdCurrencyRupee } from 'react-icons/md';

function TicketDetail({ onNext, eventDetail }) {
  const [isListShow, setIsListShow] = useState(false);
  const [ticket, setTicket] = useState({
    ticket_Name: '',
    ticket_type: 'paid',
    total_Quantity: 0,
    ticket_price: 0,
    sale_start: '',
    sale_start_Time: '',
    sale_end: '',
    sale_end_Time: '',
    min_booking: 0,
    max_booking: 0,
    ticket_description: '',
    isGst: "No",
    GST: 0,
    event_id: eventDetail._id,
  });
  const [ticketList, setTicketList] = useState([]);
  // Form Validation 
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const validate = () => {
    const newErrors = {};
    if (!ticket.ticket_Name) {
      newErrors.ticket_Name = 'Ticket name is required';
    }
    if (!ticket.total_Quantity) {
      newErrors.total_Quantity = 'Quantity is required';
    }
    setErrors(newErrors);
    const isValidForm = Object.keys(newErrors).length === 0;
    setIsValid(isValidForm);
    return isValidForm;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (validate() && isValid) {
        const result = await axios.post(`${API_URL}/ticket/createticket`, ticket);
        if (result.status === 200) {
          const data = { ticket_id: result.data.ticketCreated._id, event_id: ticket.event_id }
          await axios.put(`${API_URL}/api/addeventticket`, data)
          getAllTickets();
          setIsListShow(true);
          setTicket({
            ...ticket,
            ticket_Name: '',
            ticket_type: 'paid',
            total_Quantity: 0,
            ticket_price: 0,
            sale_start: '',
            sale_start_Time: '',
            sale_end: '',
            sale_end_Time: '',
            min_booking: 0,
            max_booking: 0,
            ticket_description: '',
            isGst: "No",
            GST: 0,
            event_id: eventDetail._id,
          })
        }
        else {
          alert("Something went wrong");
        }
      }
    } catch (error) {
      alert("Unable to add ticket");
    }
  };

  // Function to Fetch and store all ticket list
  const getAllTickets = async () => {
    const ticketStore = await axios.get(`${API_URL}/ticket/gettickets/${ticket.event_id}`);
    setTicketList(ticketStore.data.all_tickets);
  }

  // Delete ticket functionality
  const handleDeleteTicket = async (ticket_id) => {
    await axios.delete(`${API_URL}/ticket/tickets/${ticket_id}`)
      .then((response) => {
        getAllTickets();
      }).catch((err) => alert("Unable to delete ticket"));

  };
  const handleChange = (tab) => {
    onNext(tab, isValid);
  }

  return (
    <div>
      {/* Ticket Details Form */}
      <div className={`${!isListShow ? "d-block" : "d-none"}`}>
        <form onSubmit={handleSubmit}>
          <div className='addeventdetailDiv'>
            <div className="container">
              <div id="ticket-detail">
                <div className='mt-1'>

                  {/* <div className={`text-start mb-4 ${ticket.ticket_type === "paid" ? "d-block" : "d-none"}`}>
                    <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='ticket_Name'>Ticket Name</label> <span className='text-danger fs-4'>*</span>
                    <input className='form-control' style={{borderColor:"#2C9CF0"}}
                      type='text'
                      name='ticket_Name'
                      placeholder='Early Bird/RSVP/Regular Entry etc'
                      value={ticket.ticket_Name}
                      onChange={(e) => {
                        setTicket({
                          ...ticket,
                          ticket_Name: e.target.value
                        });
                      }}
                      required
                    />
                    {errors.ticket_Name && <p className='text-danger ms-3'>{errors.ticket_Name}</p>}
                  </div> */}
                  <div className={`venuelatitude`}>
                    <div className="fulllength me-5 text-start mb-3">
                      <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='ticket_Name'>Ticket Name</label> <span className='text-danger fs-4'>*</span>
                      <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                        type='text'
                        name='ticket_Name'
                        placeholder='Early Bird/RSVP/Regular Entry etc'
                        value={ticket.ticket_Name}
                        onChange={(e) => {
                          setTicket({
                            ...ticket,
                            ticket_Name: e.target.value
                          });
                        }}
                        required
                      />
                      {errors.ticket_Name && <p className='poppins16 text-danger ms-3'>{errors.ticket_Name}</p>}
                    </div>
                    <div className="fulllength text-start mb-3">
                      <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='ticket_description'>Ticket Description</label>
                      <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                        type='text'
                        name='ticket_description'
                        placeholder='Cover/No-cover/Entire Only etc'
                        value={ticket.ticket_description}
                        onChange={(e) => {
                          setTicket({
                            ...ticket,
                            ticket_description: e.target.value
                          });
                        }} />
                    </div>
                  </div>
                  <div className={`venuelatitude`}>
                    <div className="fulllength me-5 text-start mb-3">
                      <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='total_Quantity'>Total Quantity</label> <span className='text-danger fs-4'>*</span>
                      <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                        type='number'
                        name='total_Quantity'
                        placeholder='Enter Total Quantity'
                        value={ticket.total_Quantity}
                        onChange={(e) => {
                          setTicket({
                            ...ticket,
                            total_Quantity: e.target.value
                          });
                        }}
                        required
                      />
                      {errors.total_Quantity && <p className='text-danger ms-3'>{errors.total_Quantity}</p>}
                    </div>
                    <div className={`fulllength text-start mb-3 ${ticket.ticket_type === "paid" || ticket.ticket_type === "private" ? "d-block" : "d-none"}`}>
                      <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='ticket_price'>Ticket Price</label> <span className='text-danger fs-4'>*</span>
                      <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                        type='number'
                        name='ticket_price'
                        placeholder='Enter Ticket Price in INR'
                        value={ticket.ticket_price}
                        onChange={(e) => {
                          setTicket({
                            ...ticket,
                            ticket_price: e.target.value
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3 text-start">
                      <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='sale_start'>Ticket sale Starts from</label>
                      <div className='d-flex px-1'>
                        <input className='form-control me-3'
                          type='date'
                          name='sale_start'
                          value={ticket.sale_start}
                          onChange={(e) => {
                            setTicket({
                              ...ticket,
                              sale_start: e.target.value
                            });
                          }}
                        />
                        <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                          type='time'
                          name='sale_start_Time'
                          value={ticket.sale_start_Time}
                          onChange={(e) => {
                            setTicket({
                              ...ticket,
                              sale_start_Time: e.target.value
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3 text-start">
                      <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='sale_end'>Ticket Sale Ends at</label>
                      <div className='d-flex px-1 mb-2'>
                        <input className='form-control me-3'
                          type='date'
                          name='sale_end'
                          value={ticket.sale_end}
                          onChange={(e) => {
                            setTicket({
                              ...ticket,
                              sale_end: e.target.value
                            });
                          }}
                        />
                        <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                          type='time'
                          name='sale_end_Time'
                          value={ticket.sale_end_Time}
                          onChange={(e) => {
                            setTicket({
                              ...ticket,
                              sale_end_Time: e.target.value
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`venuelatitude`}>
                    <div className="fulllength mb-3 me-5 text-start">
                      <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='min_booking'>Minimum per Booking</label>
                      <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                        type='number'
                        name='min_booking'
                        placeholder='Enter Total Quantity'
                        value={ticket.min_booking}
                        onChange={(e) => {
                          setTicket({
                            ...ticket,
                            min_booking: e.target.value
                          });
                        }}
                      />
                    </div>
                    <div className="fulllength mb-3 text-start">
                      <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='max_booking'>Maximum per Booking</label>
                      <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                        type='number'
                        name='max_booking'
                        placeholder='Enter Event Name'
                        value={ticket.max_booking}
                        onChange={(e) => {
                          setTicket({
                            ...ticket,
                            max_booking: e.target.value
                          });
                        }}
                      />
                    </div>
                  </div>


                  <div className="fulllength text-start mb-4">
                    <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='ticket_type'>Ticket Type</label>
                    <div className='d-flex justify-content-between'>
                      <button type='button'
                        className={`"btn ticket-type-btn" ${ticket.ticket_type === "paid" ? "btn primary text-white" : "btn border-primary"}`}
                        style={{ borderColor: "#2C9CF0", width: "47%" }}
                        onClick={() => { setTicket({ ...ticket, ticket_type: "paid" }) }} >
                        Paid
                      </button>
                      <button type='button'
                        className={`"btn  ticket-type-btn" ${ticket.ticket_type === "free" ? "btn primary text-white" : "btn border-primary"}`}
                        style={{ borderColor: "#2C9CF0", width: "47%" }}
                        onClick={() => { setTicket({ ...ticket, ticket_type: "free" }) }} >
                        Free
                      </button>
                      {/* <button type='button' className={`"btn tickettypebtn border-primary" ${ticket.ticket_type === "private" ? "btn primary text-white" : "btn border-primary"}`} onClick={() => { setTicket({ ...ticket, ticket_type: "private" }) }}> Private</button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='ticketfooterDiv d-flex justify-content-center py-3'>
            <button type='submit' className='btn createticketbtn'>Create Ticket</button>
          </div>
        </form>
      </div>
      <div className={`${isListShow ? "d-block" : "d-none"}`}>
        {/* Added Ticket List */}
        <div className={`addeventdetailDiv mt-4`} id="largesScreenDevice">
          {
            ticketList.map((tickets) => {
              return (
                <div className='row container px-lg-2 py-2 mb-3 border-bottom border-dark m-auto' style={{ width: "90%" }}
                  key={tickets._id}>
                  <div className='col-lg-3 col-md-3 col-sm-3 col-12 text-start d-flex flex-column' >
                    <span className='fw-bold'>{tickets.ticket_Name}</span>
                    <span style={{ fontSize: "small" }}>Sale Starts at {tickets.sale_start} - Ends at {tickets.sale_end}</span>
                  </div>
                  <div className='col-lg-2'>
                    <p>{tickets.total_Quantity} Ticket(s)</p>
                  </div>
                  <div className='col-lg-2 col-md-2 col-sm-2 col-3'><MdCurrencyRupee /> {tickets.ticket_price}</div>
                  {/* <div className='col-lg-1 col-md-1 col-sm-1 col-3'><Trash size={17} onClick={() => handleDeleteTicket(tickets._id)} /></div> */}
                  <div className='col-lg-1 col-md-1 col-sm-1 col-3'>
                    {/* <Edit2 size={17} /> */}
                  </div>
                  <div className='col-lg-1 col-md-1 col-sm-1 col-3'><Trash size={17} onClick={() => handleDeleteTicket(tickets._id)} /></div>
                  <div className='col-lg-1 col-md-1 col-sm-1 col-3'>
                    {/* <EyeOff size={17} /> */}
                  </div>
                  <div className='col-lg-2 col-md-2 col-sm-2 col-12'><span className='rounded-5 ticketactivebtn m-auto'>Active</span></div>
                </div>
              )
            })
          }
          <div className='text-center'>
            <button
              className="btn addticketbtn my-3 fw-bold"
              onClick={() => { setIsListShow(false) }}>+Add Another Ticket</button>
          </div>
          {/* <div className='text-start w-75 mb-5 m-auto'>
          <p className='fw-bold'>Does your ticketing involve GST</p>
          <input type='radio' name="isGst" /> Yes
          <input type='radio' className='ms-5' name="isGst" /> No
        </div>
        <div className='col-lg-2 text-start w-75 mb-5 m-auto'>
          <select type="number" name='GST' onChange={(e) => {
                      setTicket({
                        ...ticket,
                        GST: e.target.value
                      });
                    }}>
            <option value="default" selected>18</option>
            <option value="19" selected>19</option>
            <option value="20" selected>20</option>
            <option value="10" selected>10</option>
          </select> %
        </div> */}
        </div>
        <div className={`addeventdetailDiv mt-4`} id="SmallScreenDevice">
          {
            ticketList.map((tickets) => {
              return (
                <div className='row container px-lg-2 py-2 mb-3 border-bottom border-dark m-auto' style={{ width: "99%" }}
                  key={tickets._id}>
                  <div className='col-6 text-start d-flex flex-column'>
                    <span className='fw-bold poppins16-sm'>{tickets.ticket_Name}</span>
                    <span className='poppins10-sm'>Sale Starts at {tickets.sale_start} - Ends at {tickets.sale_end}</span>
                  </div>
                  <div className='col-3'>
                    <p className='poppins14-sm'>{tickets.total_Quantity} Ticket(s)</p>
                  </div>
                  <div className='col-3'>
                    <p className='poppins14-sm'><MdCurrencyRupee /> {tickets.ticket_price}</p>
                  </div>
                  <div className='col-6'>
                    <div className='col-lg-2 col-md-2 col-sm-2 col-12'><span className='rounded-5 ticketactivebtn m-auto'>Active</span></div>
                  </div>
                  <div className='col-6 d-flex justify-content-center'>
                    <Trash size={17} onClick={() => handleDeleteTicket(tickets._id)} />
                    {/* <Edit2 size={17} /> */}
                    {/* <EyeOff size={17} /> */}
                  </div>
                </div>
              )
            })
          }
          <div className='text-center'>
            <button
              className="btn addticketbtn my-3 fw-bold"
              onClick={() => { setIsListShow(false) }}>+Add Another Ticket</button>
          </div>
          {/* <div className='text-start w-75 mb-5 m-auto'>
          <p className='fw-bold'>Does your ticketing involve GST</p>
          <input type='radio' name="isGst" /> Yes
          <input type='radio' className='ms-5' name="isGst" /> No
        </div>
        <div className='col-lg-2 text-start w-75 mb-5 m-auto'>
          <select type="number" name='GST' onChange={(e) => {
                      setTicket({
                        ...ticket,
                        GST: e.target.value
                      });
                    }}>
            <option value="default" selected>18</option>
            <option value="19" selected>19</option>
            <option value="20" selected>20</option>
            <option value="10" selected>10</option>
          </select> %
        </div> */}
        </div>
        <div className='adminfooterDiv'>
          {/* <button type='button'
            className={`btn btn-secondary me-5 px-5 `}
            onClick={()=>handleChange("add-details")}
          >Back</button> */}
          <button type='button' className='btn basicnextbtn' onClick={() => handleChange("add-on")}>Next</button>
        </div>
      </div>
    </div>

  )
}

export default TicketDetail