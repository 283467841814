import React, { useState } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import axios from 'axios';
import { API_URL } from '../../config';

const Upload_Document = ({ onNext }) => {
    const [documents, setDocuments] = useState({
        pan_card: "",
        cancel_cheque: ""
    });

    const [uploaded_pan, setUploaded_pan] = useState("");
    const [uploaded_cheque, setUploaded_cheque] = useState("");
    const [errors, setErrors] = useState({});
    const [isPanUploaded, setIsPanUploaded] = useState(false); // Track if PAN card is uploaded
    const [isChequeUploaded, setIsChequeUploaded] = useState(false); // Track if canceled cheque is uploaded

    // Validate form fields
    const validate = () => {
        const newErrors = {};

        // Validate PAN card selection and upload
        if (!documents.pan_card) {
            newErrors.pan_card = 'PAN card is required';
        } else if (!isPanUploaded) {
            newErrors.pan_upload = 'Please upload the selected PAN card';
        }

        // Validate canceled cheque selection and upload
        if (!documents.cancel_cheque) {
            newErrors.cancel_cheque = 'Canceled cheque is required';
        } else if (!isChequeUploaded) {
            newErrors.cheque_upload = 'Please upload the selected canceled cheque';
        }

        setErrors(newErrors);
        // Check if the form is valid
        return Object.keys(newErrors).length === 0;
    };

    // Functionality to select the PAN card
    const handleFileSelection = (event) => {
        setUploaded_pan(event.target.files[0].name);
        setDocuments({
            ...documents,
            pan_card: event.target.files[0]
        });
    };

    // Functionality to select the canceled cheque
    const handleFileSelection1 = (event) => {
        setUploaded_cheque(event.target.files[0].name);
        setDocuments({
            ...documents,
            cancel_cheque: event.target.files[0]
        });
    };

    // Functionality to upload the PAN card
    const handleFileUpload = async () => {
        const fd = new FormData();
        fd.append('image', documents.pan_card);

        try {
            const response = await axios.post(`${API_URL}/vendor/image/uploadpan-card`, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 200) {
                const uploadedFilename = response.data.file.filename;
                setDocuments(prev => ({
                    ...prev,
                    pan_card: uploadedFilename
                }));
                setIsPanUploaded(true); // Mark PAN card as uploaded
                alert('PAN card uploaded successfully');
            }
        } catch (error) {
            alert('Error uploading PAN card');
        }
    };

    // Functionality to upload the canceled cheque
    const handleFileUpload1 = async () => {
        if (!documents.cancel_cheque) {
            setErrors({ ...errors, cancel_cheque: true });
            alert('Please select a canceled cheque file');
            return;
        }

        const fd = new FormData();
        fd.append('image', documents.cancel_cheque);

        try {
            const response = await axios.post(`${API_URL}/vendor/image/uploadcancel_cheque`, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 200) {
                const uploadedFilename = response.data.file.filename;
                setDocuments(prev => ({
                    ...prev,
                    cancel_cheque: uploadedFilename
                }));
                setIsChequeUploaded(true); // Mark cheque as uploaded
                alert('Canceled cheque uploaded successfully');
            }
        } catch (error) {
            alert('Error uploading canceled cheque');
        }
    };

    // Validate form before submission
    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate the form
        const isValid = validate();
        
        if (isValid) {
            onNext("agreement", true, documents);
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div>
                    <div className='addeventdetailDiv'>
                        <div className='mt-2'>
                            <span className='poppins30 fw-semibold'>Document upload instructions:</span>
                            <div>
                                <ol>
                                    <li>
                                        <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eum tempore consectetur minima ex voluptate nesciunt fugiat est possimus, laboriosam nobis, facere aliquid consequuntur, ea tenetur illum vitae saepe odio quis?</span>
                                    </li>
                                    <li>
                                        <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eum tempore consectetur minima ex voluptate nesciunt fugiat est possimus, laboriosam nobis, facere aliquid consequuntur, ea tenetur illum vitae saepe odio quis?</span>
                                    </li>
                                    <li>
                                        <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eum tempore consectetur minima ex voluptate nesciunt fugiat est possimus, laboriosam nobis, facere aliquid consequuntur, ea tenetur illum vitae saepe odio quis?</span>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div className='container'>
                            <div className='container d-flex justify-content-between'>
                                <div>
                                    <div className='mt-3'>
                                        <div className="text-center mb-3">
                                            <label className="poppins20 fw-semibold ms-1 mb-2 mt-3 text-center" htmlFor='pan_card'>PAN Card</label>
                                            <div className='coverImg_style m-auto mb-3'>
                                                <div className='uploadFile text-center'>
                                                    <AiOutlineCloudUpload className='uploadIcon' />
                                                    {documents.pan_card ? (
                                                        <div>
                                                            <span className='poppins16 fw-bold'>Uploaded: </span> <p>{uploaded_pan}</p>
                                                        </div>
                                                    ) : (
                                                        <div className='text-center'>
                                                            <p className='poppins16'>Drag and Drop files or <a href="#">Browse</a></p>
                                                            <p className='poppins12'>Supported format: jpeg, pdf</p>
                                                        </div>
                                                    )}
                                                </div>
                                                <input type="file" className='selectFileStyle w-100 h-100' onChange={handleFileSelection} />
                                            </div>
                                            {errors.pan_card && <p className="text-danger">PAN card is required</p>}
                                            {errors.pan_upload && <p className="text-danger">Please upload the selected PAN card</p>}
                                            <button type='button' className="btn uploadbtn" onClick={handleFileUpload}>Upload PAN Card</button>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className='mt-3'>
                                        <div className="text-center mb-3">
                                            <label className="poppins20 fw-semibold ms-1 mb-2 mt-3 text-center" htmlFor='cancel_cheque'>Canceled Cheque</label>
                                            <div className='coverImg_style m-auto mb-3'>
                                                <div className='uploadFile text-center'>
                                                    <AiOutlineCloudUpload className='uploadIcon' />
                                                    {documents.cancel_cheque ? (
                                                        <div>
                                                            <span className='poppins16 fw-bold'>Uploaded: </span> <p>{uploaded_cheque}</p>
                                                        </div>
                                                    ) : (
                                                        <div className='text-center'>
                                                            <p className='poppins16'>Drag and Drop files or <a href="#">Browse</a></p>
                                                            <p className='poppins12'>Supported format: jpeg, pdf</p>
                                                        </div>
                                                    )}
                                                </div>
                                                <input type="file" className='selectFileStyle w-100 h-100' onChange={handleFileSelection1} />
                                            </div>
                                            {errors.cancel_cheque && <p className="text-danger">Canceled cheque is required</p>}
                                            {errors.cheque_upload && <p className="text-danger">Please upload the selected canceled cheque</p>}
                                            <button type='button' className="btn uploadbtn" onClick={handleFileUpload1}>Upload Canceled Cheque</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='adminfooterDiv'>
                        <button type='submit' className='btn basicnextbtn'>Next</button>
                    </div>
                </div>
            </form>
        </>
    );
};

export default Upload_Document;
