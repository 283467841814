import React, { useEffect, useState } from 'react';
import './ProfilePage.css';
import NavigationBar from '../Components/NavigationBar';
import SampleImg from '../../Assets/sampleImage.jpg';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { API_URL } from '../../config';
import { AiOutlineAudit, AiOutlineUser } from "react-icons/ai";
import { TbArrowLeftFromArc } from "react-icons/tb";
import { BsTicketDetailed } from "react-icons/bs";
import { BiFile, BiPlus } from "react-icons/bi";
import { Calendar, Clock } from 'react-feather';

function ProfilePage() {
    const [events, setEvents] = useState([]);
    const user = JSON.parse(localStorage.getItem('userData'));
    const navigate = useNavigate();
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);

    const handleLinkClick = (path) => {
        setActiveLink(path);
    };

    const handleLogout = () => {
        localStorage.clear();
        navigate('/');
    };

    const getEventList = async () => {
        try {
            const result = await axios.get(`${API_URL}/api/events`);
            setEvents(result.data);
        } catch (error) {
            alert("Something went wrong");
        }
    };

    const totalticket = (ticket) => {
        let totaltickets = 0;
        for (let i = 0; i < ticket.length; i++) {
            totaltickets += ticket[i].total_Quantity;
        }
        return totaltickets.toLocaleString();
    };

    const formatTime = (timeString) => {
        if (!timeString) {
            timeString = "00:00:00";
        }

        const dateObj = new Date(`1970-01-01T${timeString}Z`);
        let hours = dateObj.getUTCHours();
        const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;

        return `${hours}:${minutes} ${ampm}`;
    };

    useEffect(() => {
        getEventList();
    }, []);

    return (
        <div className='profilepage'>
            <NavigationBar />
            <div className='row'>
                <div className='col-lg-2 col-md-2 col-sm-4 col-3 border-end border-2' style={{ minHeight: "790px" }}>
                    <div className='sideBar'>
                        <div className='userProfile'>
                            <img className='eventImg' src={SampleImg} alt='profilePic'></img>
                            <h4 className='username mt-3'>Hi, {user ? user.firstName : "Guest"}</h4>
                        </div>
                        <div className='side-NavBar'>
                            <div className={`nav-content ${activeLink === "/profile" ? "bg-primary text-white" : ""}`}>
                                <Link to="/profile" className='link-dark link-underline-opacity-0 fw-semibold'
                                    onClick={() => handleLinkClick("/profile")}>
                                    <AiOutlineUser /> <span className="hideinSmall">Profile</span>
                                </Link>
                            </div>
                            <div className={`nav-content ${activeLink === "/yourtickets" ? "bg-primary text-white" : ""}`}>
                                <Link to="#" className='link-dark link-underline-opacity-0 fw-semibold'
                                    onClick={() => handleLinkClick("/yourtickets")}>
                                    <BsTicketDetailed /> <span className="hideinSmall">Your Ticket</span>
                                </Link>
                            </div>
                            <div className={`nav-content ${activeLink === "/reward-points" ? "bg-primary text-white" : ""}`}>
                                <Link to="#" className='link-dark link-underline-opacity-0 fw-semibold'
                                    onClick={() => handleLinkClick("/reward-points")}>
                                    <AiOutlineAudit /> <span className="hideinSmall">Reward Points</span>
                                </Link>
                            </div>
                            <div className={`nav-content ${activeLink === "/settings" ? "bg-primary text-white" : ""}`}>
                                <Link to="#" className='link-dark link-underline-opacity-0 fw-semibold'
                                    onClick={() => handleLinkClick("/settings")}>
                                    <BiFile /> <span className="hideinSmall">Setting</span>
                                </Link>
                            </div>
                            <div className='nav-content'>
                                <Link to="/" className='link-dark link-underline-opacity-0 fw-semibold'
                                    onClick={handleLogout}>
                                    <TbArrowLeftFromArc /> <span className="hideinSmall">Logout</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-10 col-md-10 col-sm-8 col-9'>
                    <div className='lists'>
                        <div className='d-flex justify-content-between border-bottom border-primary' style={{ marginTop: "34px" }}>
                            <span className='profileheading fw-semibold'>Your Profile</span>
                            <Link to='/details' className='createnewbtn'><BiPlus size={15}/> Create Event</Link>
                        </div>
                        <div className='ListofEvents'>
                            <p className='listheading fw-semibold'>Your events</p>
                            <div>
                                {events.length > 0 ? (
                                    events.map((event, index) => {
                                        return (
                                            <div className="row border border-dark mx-lg-1 rounded-4 p-1 mb-2" key={index}>
                                                <div className='col-lg-3 col-md-3 col-sm-4 col-4 d-flex flex-column'>
                                                    <span className='poppin18 fw-semibold'>{event.event_name} </span>
                                                    <span className='poppin14'>#MFS0001</span>
                                                </div>
                                                <div className='col-lg-2 col-md-2 col-sm-4 col-4 d-flex flex-column'>
                                                    <span className='poppin18 fw-semibold'><Calendar size={12} /> Date </span>
                                                    <span className='poppin14'>{new Date(event.event_starts_date).toLocaleDateString('en-GB').replace(/\//g, '-')}</span>
                                                </div>
                                                <div className='col-lg-2 col-md-2 col-sm-4 col-4 d-flex justify-content-between'>
                                                    <div className='d-flex flex-column'>
                                                        <span className='poppin18 fw-semibold'><Clock size={12} /> Time </span>
                                                        <span className='poppin14'>
                                                        {formatTime(event?.event_starts_Time)} - {formatTime(event?.event_ends_Time)}
                                                        </span>
                                                    </div>
                                                    <div className='border-end border-secondary-subtle border-2 mt-1 me-2' id="hideinSmall" style={{ height: "45px" }}></div>
                                                </div>
                                                <div className='col-lg-2 col-md-2 col-sm-4 col-4 d-flex flex-column'>
                                                    <span className='poppin18 fw-semibold'> Tickets Hosted </span>
                                                    <span className='poppin14'>{totalticket(event.ticket)}</span>
                                                </div>
                                                <div className='col-lg-2 col-md-2 col-sm-4 col-4 d-flex flex-column'>
                                                    <span className='poppin18 fw-semibold'> Venue </span>
                                                    <span className='poppin14'>{event.event_venue}</span>
                                                </div>
                                                <div className='col-lg-1 col-md-1 col-sm-4 col-4'>
                                                    <button className='border-primary mt-3 rounded-pill px-lg-3 bg-white text-primary'
                                                        style={{ marginLeft: "-10px", fontSize: "12px" }}>View</button>
                                                </div>
                                            </div>
                                        )
                                    })
                                ) : <p>.........No Event Added........</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfilePage;
