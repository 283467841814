import './App.css';
import { Route, Routes } from 'react-router-dom';
import AddNewEvent from './Vendor/Pages/AddNewEvent';
import ProfilePage from './Vendor/Pages/ProfilePage';
import LoginPage from './Vendor/Pages/LoginPage';
import AccountSetUp from './Vendor/Pages/AccountSetUp';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/signup" element={<AccountSetUp />} />
        {/* Vendor Routes */}
        <Route path="/details" element={<AddNewEvent />} />
        <Route path="/profile" element={<ProfilePage />} />
      </Routes>
    </div>
  );
}

export default App;
