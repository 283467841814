import React, { useEffect, useState } from 'react';
import './Basic_info.css';
import apiService from "../../services/apiService";

const BasicInformation = ({ onNext }) => {
    const userData = apiService.getData();
    const [errors, setErrors] = useState({});
    const [isValid, setIsValid] = useState(false);
    const [formData, setFormData] = useState({
        _id: userData.uid,
        phoneNumber: userData.phoneNumber,
        vendorType: "organiser",
        name: "",
        pan_no: "",
        address: "",
        isGSTIN_No: "yes",
        GSTIN_Details: [{
            GSTIN_No: "",
            state: ""
        }],
        userName: "",
        email: "",
        bank_Account_type: "",
        beneficiar_name: "",
        bank_account_no: "",
        bank_name: "",
        IFSC_code: "",
        ITR_Filed: "no",
    });

    // Form Validation
    const validate = () => {
        const newErrors = {};
        if (!formData.name) {
            newErrors.name = 'Name is required';
        }
        if (!formData.pan_no) {
            newErrors.pan_no = 'PAN number is required';
        }
        if (!formData.address) {
            newErrors.address = 'Address is required';
        }
        if (!formData.userName) {
            newErrors.userName = 'Username is required';
        }
        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email is invalid';
        }
        if (!formData.bank_Account_type) {
            newErrors.bank_Account_type = 'Bank account number is required';
        }
        if (!formData.beneficiar_name) {
            newErrors.beneficiar_name = 'Bank account number is required';
        }
        if (!formData.bank_account_no) {
            newErrors.bank_account_no = 'Bank account number is required';
        }
        if (!formData.bank_name) {
            newErrors.bank_name = 'Bank name is required';
        }
        if (!formData.IFSC_code) {
            newErrors.IFSC_code = 'IFSC code is required';
        }
        if (formData.isGSTIN_No === "yes") {
            if (!formData.GSTIN_Details[0].GSTIN_No) {
                newErrors.GSTIN_No = 'GSTIN number is required';
            }
            if (!formData.GSTIN_Details[0].state) {
                newErrors.GSTIN_state = 'State for GSTIN is required';
            }
        }
        setErrors(newErrors);
        const isValidForm = Object.keys(newErrors).length === 0;
        setIsValid(isValidForm);
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        validate();
        try {
            if (isValid) {
                onNext("upload_doc", isValid, formData);
            }
        } catch (error) {
            alert("you have to fill all Fields");
        }
    }

    const handleAddGSTINRow = () => {
        // Add a new row to GSTIN_Details array
        setFormData({
            ...formData,
            GSTIN_Details: [...formData.GSTIN_Details, { GSTIN_No: "", state: "" }]
        });
    }

    const handleGSTINChange = (index, field, value) => {
        const updatedGSTIN = formData.GSTIN_Details.map((gstin, i) =>
            index === i ? { ...gstin, [field]: value } : gstin
        );
        setFormData({
            ...formData,
            GSTIN_Details: updatedGSTIN
        });
    }

    return (
        <>
            <div>
                <form onSubmit={handleSubmit}>
                    {/* Basics Detail form */}
                    <div>
                        <div className='addeventdetailDiv'>
                            <div className='container'>
                                <div className='container'>
                                    <div className='mt-lg-2 mt-md-2'>
                                        {/* Vendor Type Selection */}
                                        <div>
                                            <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='event_name'>You are best described as?</label>
                                            <div className='row pe-5 mb-lg-4 mb-md-3 mb-3'>
                                                <div className='col-md-6 col-12'>
                                                    <div className='moveRight'>
                                                        <input type="radio" name="VendorType" value="organiser"
                                                            onChange={(e) => {
                                                                setFormData({
                                                                    ...formData,
                                                                    vendorType: e.target.value
                                                                });
                                                            }}
                                                            defaultChecked
                                                        /> <span> Event Organiser/Individual </span>
                                                    </div>
                                                </div>
                                                <div className='col-md-6 col-12'>
                                                    <div className='moveRight'>
                                                        <input type="radio" name="VendorType" value="venue"
                                                            onChange={(e) => {
                                                                setFormData({
                                                                    ...formData,
                                                                    vendorType: e.target.value
                                                                });
                                                            }}
                                                        /> Venue
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='border-bottom border-2 border-light-subtle'>
                                            <span className='poppins30 fw-semibold'>General Details</span>
                                        </div>

                                        {formData.vendorType === "organiser" && (
                                            <div>
                                                {/* Organiser/Company Details */}
                                                <div className='row mt-1'>
                                                    <div className='col-lg-6 col-md-6'>
                                                        <div className="text-start mb-3">
                                                            <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='name'>Organiser’s name/Company Name</label> <span className='text-danger fs-4'>*</span>
                                                            <input
                                                                className='form-control border-primary'
                                                                type='text'
                                                                name='name'
                                                                placeholder='Name'
                                                                value={formData.name}
                                                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                                            />
                                                            {errors.name && <p className='text-danger ms-3'>{errors.name}</p>}
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-6'>
                                                        <div className="text-start mb-3">
                                                            <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='pan_no'>Organiser’s /Company’s PAN card Number</label> <span className='text-danger fs-4'>*</span>
                                                            <input
                                                                className='form-control border-primary'
                                                                type='text'
                                                                name='pan_no'
                                                                placeholder='PAN number'
                                                                value={formData.pan_no}
                                                                onChange={(e) => setFormData({ ...formData, pan_no: e.target.value })}
                                                            />
                                                            {errors.pan_no && <p className='text-danger ms-3'>{errors.pan_no}</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row mt-1'>
                                                    <div className="text-start mb-3">
                                                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='address'>Registered Address</label> <span className='text-danger fs-4'>*</span>
                                                        <textarea
                                                            className='form-control border-primary'
                                                            type='text'
                                                            name='address'
                                                            placeholder='Address'
                                                            value={formData.address}
                                                            onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                                                        ></textarea>
                                                        {errors.address && <p className='text-danger ms-3'>{errors.address}</p>}
                                                    </div>
                                                </div>

                                                {/* GSTIN Section */}
                                                <div>
                                                    <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='isGSTIN_No'>Do you have GSTIN number?</label> <span className='text-danger fs-4'>*</span>
                                                    <div className='d-flex pe-5 mb-lg-4 mb-md-3 mb-3'>
                                                        <div className='me-5'>
                                                            <input type="radio" name="isGSTIN_No" value="yes"
                                                                onChange={(e) => {
                                                                    setFormData({
                                                                        ...formData,
                                                                        isGSTIN_No: e.target.value
                                                                    });
                                                                }}
                                                                defaultChecked
                                                            /> <span> Yes </span>
                                                        </div>
                                                        <div className='ms-5'>
                                                            <input type="radio" name="isGSTIN_No" value="no"
                                                                onChange={(e) => {
                                                                    setFormData({
                                                                        ...formData,
                                                                        isGSTIN_No: e.target.value
                                                                    });
                                                                }}
                                                            /> No
                                                        </div>
                                                    </div>
                                                </div>

                                                {formData.isGSTIN_No === "yes" && (
                                                    <>
                                                        {formData.GSTIN_Details.map((gstin, index) => (
                                                            <div className='row mt-1' key={index}>
                                                                <div className='col-lg-6 col-md-6'>
                                                                    <div className="text-start mb-3">
                                                                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor={`GSTIN_No_${index}`}>GSTIN Number</label>
                                                                        <input
                                                                            className='form-control border-primary'
                                                                            type='text'
                                                                            name={`GSTIN_No_${index}`}
                                                                            placeholder='GSTIN Number'
                                                                            value={gstin.GSTIN_No}
                                                                            onChange={(e) => handleGSTINChange(index, 'GSTIN_No', e.target.value)}
                                                                        />
                                                                        {errors.GSTIN_No && <p className='text-danger ms-3'>{errors.GSTIN_No}</p>}
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-6 col-md-6'>
                                                                    <div className="text-start mb-3">
                                                                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor={`state_${index}`}>State</label>
                                                                        <input
                                                                            className='form-control border-primary'
                                                                            type='text'
                                                                            name={`state_${index}`}
                                                                            placeholder='State'
                                                                            value={gstin.state}
                                                                            onChange={(e) => handleGSTINChange(index, 'state', e.target.value)}
                                                                        />
                                                                        {errors.state && <p className='text-danger ms-3'>{errors.state}</p>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <button type='button' className='addArrarybtn' onClick={handleAddGSTINRow}>+ Add another GSTIN Number</button>
                                                    </>
                                                )}
                                                <div className='border-bottom border-2 border-light-subtle'>
                                                    <span className='poppins30 fw-semibold'>Contact Details</span>
                                                </div>
                                                <div>
                                                    <div className='row mt-1'>
                                                        <div className='col-lg-6 col-md-6'>
                                                            <div className="text-start mb-3">
                                                                <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='userName'>Full Name</label> <span className='text-danger fs-4'>*</span>
                                                                <input
                                                                    className='form-control border-primary'
                                                                    type='text'
                                                                    name='userName'
                                                                    placeholder='Enter your full Name'
                                                                    value={formData.userName}
                                                                    onChange={(e) => setFormData({ ...formData, userName: e.target.value })}
                                                                />
                                                                {errors.userName && <p className='text-danger ms-3'>{errors.userName}</p>}
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6 col-md-6'>
                                                            <div className="text-start mb-3">
                                                                <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='email'>Email</label> <span className='text-danger fs-4'>*</span>
                                                                <input
                                                                    className='form-control border-primary'
                                                                    type='email'
                                                                    name='email'
                                                                    placeholder='Email'
                                                                    value={formData.email}
                                                                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                                                />
                                                                {errors.email && <p className='text-danger ms-3'>{errors.email}</p>}
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6 col-md-6'>
                                                            <div className="text-start mb-3">
                                                                <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='phoneNumber'>Mobile Number</label>
                                                                <input
                                                                    className='form-control border-primary'
                                                                    type='tel'
                                                                    name='phoneNumber'
                                                                    placeholder='Mobile number'
                                                                    value={formData.phoneNumber}
                                                                    onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='border-bottom border-2 border-light-subtle'>
                                                    <span className='poppins30 fw-semibold'>Bank Details</span>
                                                </div>
                                                <div>
                                                    <div className='row mt-1'>
                                                        <div className='col-lg-6 col-md-6'>
                                                            <div className="text-start mb-3">
                                                                <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='bank_Account_type'>Account Type</label> <span className='text-danger fs-4'>*</span>
                                                                <select
                                                                    className='form-control border-primary'
                                                                    type='text'
                                                                    name='bank_Account_type'
                                                                    value={formData.bank_Account_type}
                                                                    onChange={(e) => setFormData({ ...formData, bank_Account_type: e.target.value })}
                                                                >
                                                                    <option value="">Select Account </option>
                                                                    <option value="current_acc">Current Account</option>
                                                                </select>
                                                                {errors.bank_Account_type && <p className='text-danger ms-3'>{errors.bank_Account_type}</p>}
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6 col-md-6'>
                                                            <div className="text-start mb-3">
                                                                <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='beneficiar_name'>Beneficiary Name</label> <span className='text-danger fs-4'>*</span>
                                                                <input
                                                                    className='form-control border-primary'
                                                                    type='beneficiar_name'
                                                                    name='beneficiar_name'
                                                                    placeholder='Enter your Full Name'
                                                                    value={formData.beneficiar_name}
                                                                    onChange={(e) => setFormData({ ...formData, beneficiar_name: e.target.value })}
                                                                />
                                                                {errors.beneficiar_name && <p className='text-danger ms-3'>{errors.beneficiar_name}</p>}
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6 col-md-6'>
                                                            <div className="text-start mb-3">
                                                                <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='bank_account_no'>Account Number</label> <span className='text-danger fs-4'>*</span>
                                                                <input
                                                                    className='form-control border-primary'
                                                                    type='number'
                                                                    name='bank_account_no'
                                                                    placeholder='Enter account number'
                                                                    value={formData.bank_account_no}
                                                                    onChange={(e) => setFormData({ ...formData, bank_account_no: e.target.value })}
                                                                />
                                                                {errors.bank_account_no && <p className='text-danger ms-3'>{errors.bank_account_no}</p>}
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6 col-md-6'>
                                                            <div className="text-start mb-3">
                                                                <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='bank_name'>Bank Name</label> <span className='text-danger fs-4'>*</span>
                                                                <input
                                                                    className='form-control border-primary'
                                                                    type='text'
                                                                    name='bank_name'
                                                                    value={formData.bank_name}
                                                                    onChange={(e) => setFormData({ ...formData, bank_name: e.target.value })}
                                                                />
                                                                {errors.bank_name && <p className='text-danger ms-3'>{errors.bank_name}</p>}
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6 col-md-6'>
                                                            <div className="text-start mb-3">
                                                                <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='IFSC_code'>IFSC</label> <span className='text-danger fs-4'>*</span>
                                                                <input
                                                                    className='form-control border-primary'
                                                                    type='text'
                                                                    name='IFSC_code'
                                                                    placeholder='Enter IFSC'
                                                                    value={formData.IFSC_code}
                                                                    onChange={(e) => setFormData({ ...formData, IFSC_code: e.target.value })}
                                                                />
                                                                {errors.IFSC_code && <p className='text-danger ms-3'>{errors.IFSC_code}</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {/* Venue form */}
                                        {formData.vendorType === "venue" && (
                                            <div>
                                                {/* Organiser/Company Details */}
                                                <div className='row mt-1'>
                                                    <div className='col-lg-6 col-md-6'>
                                                        <div className="text-start mb-3">
                                                            <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='name'>Organiser’s name/Company Name</label> <span className='text-danger fs-4'>*</span>
                                                            <input
                                                                className='form-control border-primary'
                                                                type='text'
                                                                name='name'
                                                                placeholder='Name'
                                                                value={formData.name}
                                                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                                            />
                                                            {errors.name && <p className='text-danger ms-3'>{errors.name}</p>}
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6 col-md-6'>
                                                        <div className="text-start mb-3">
                                                            <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='pan_no'>Organiser’s /Company’s PAN card Number</label> <span className='text-danger fs-4'>*</span>
                                                            <input
                                                                className='form-control border-primary'
                                                                type='text'
                                                                name='pan_no'
                                                                placeholder='PAN number'
                                                                value={formData.pan_no}
                                                                onChange={(e) => setFormData({ ...formData, pan_no: e.target.value })}
                                                            />
                                                            {errors.pan_no && <p className='text-danger ms-3'>{errors.pan_no}</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row mt-1'>
                                                    <div className="text-start mb-3">
                                                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='address'>Registered Address</label> <span className='text-danger fs-4'>*</span>
                                                        <textarea
                                                            className='form-control border-primary'
                                                            type='text'
                                                            name='address'
                                                            placeholder='Address'
                                                            value={formData.address}
                                                            onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                                                        ></textarea>
                                                        {errors.address && <p className='text-danger ms-3'>{errors.address}</p>}
                                                    </div>
                                                </div>

                                                {/* GSTIN Section */}
                                                <div>
                                                    <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='isGSTIN_No'>Do you have GSTIN number?</label>
                                                    <div className='d-flex pe-5 mb-lg-4 mb-md-3 mb-3'>
                                                        <div className='me-5'>
                                                            <input type="radio" name="isGSTIN_No" value="yes"
                                                                onChange={(e) => {
                                                                    setFormData({
                                                                        ...formData,
                                                                        isGSTIN_No: e.target.value
                                                                    });
                                                                }}
                                                                defaultChecked
                                                            /> <span> Yes </span>
                                                        </div>
                                                        <div className='ms-5'>
                                                            <input type="radio" name="isGSTIN_No" value="no"
                                                                onChange={(e) => {
                                                                    setFormData({
                                                                        ...formData,
                                                                        isGSTIN_No: e.target.value
                                                                    });
                                                                }}
                                                            /> No
                                                        </div>
                                                    </div>
                                                </div>

                                                {formData.isGSTIN_No === "yes" && (
                                                    <>
                                                        {formData.GSTIN_Details.map((gstin, index) => (
                                                            <div className='row mt-1' key={index}>
                                                                <div className='col-lg-6 col-md-6'>
                                                                    <div className="text-start mb-3">
                                                                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor={`GSTIN_No_${index}`}>GSTIN Number</label>
                                                                        <input
                                                                            className='form-control border-primary'
                                                                            type='text'
                                                                            name={`GSTIN_No_${index}`}
                                                                            placeholder='GSTIN Number'
                                                                            value={gstin.GSTIN_No}
                                                                            onChange={(e) => handleGSTINChange(index, 'GSTIN_No', e.target.value)}
                                                                        />
                                                                        {errors.GSTIN_No && <p className='text-danger ms-3'>{errors.GSTIN_No}</p>}
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-6 col-md-6'>
                                                                    <div className="text-start mb-3">
                                                                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor={`state_${index}`}>State</label>
                                                                        <input
                                                                            className='form-control border-primary'
                                                                            type='text'
                                                                            name={`state_${index}`}
                                                                            placeholder='State'
                                                                            value={gstin.state}
                                                                            onChange={(e) => handleGSTINChange(index, 'state', e.target.value)}
                                                                        />
                                                                        {errors.state && <p className='text-danger ms-3'>{errors.state}</p>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <button type='button' className='addArrarybtn' onClick={handleAddGSTINRow}>+ Add another GSTIN Number</button>
                                                    </>
                                                )}
                                                <div className='mt-1'>
                                                    <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='ITR_Filed'>Have you filed last 2 years ITR return?</label> <span className='text-danger fs-4'>*</span>
                                                    <div className='d-flex pe-5 mb-lg-4 mb-md-3 mb-3'>
                                                        <div className='me-5'>
                                                            <input type="radio" name="ITR_Filed" value="yes"
                                                                onChange={(e) => {
                                                                    setFormData({
                                                                        ...formData,
                                                                        ITR_Filed: e.target.value
                                                                    });
                                                                }}
                                                                defaultChecked
                                                            /> <span> Yes </span>
                                                        </div>
                                                        <div className='ms-5'>
                                                            <input type="radio" name="ITR_Filed" value="no"
                                                                onChange={(e) => {
                                                                    setFormData({
                                                                        ...formData,
                                                                        ITR_Filed: e.target.value
                                                                    });
                                                                }}
                                                            /> No
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='border-bottom border-2 border-light-subtle'>
                                                    <span className='poppins30 fw-semibold'>Contact Details</span>
                                                </div>
                                                <div>
                                                    <div className='row mt-1'>
                                                        <div className='col-lg-6 col-md-6'>
                                                            <div className="text-start mb-3">
                                                                <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='userName'>Full Name</label> <span className='text-danger fs-4'>*</span>
                                                                <input
                                                                    className='form-control border-primary'
                                                                    type='text'
                                                                    name='userName'
                                                                    placeholder='Enter your full Name'
                                                                    value={formData.userName}
                                                                    onChange={(e) => setFormData({ ...formData, userName: e.target.value })}
                                                                />
                                                                {errors.userName && <p className='text-danger ms-3'>{errors.userName}</p>}
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6 col-md-6'>
                                                            <div className="text-start mb-3">
                                                                <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='email'>Email</label> <span className='text-danger fs-4'>*</span>
                                                                <input
                                                                    className='form-control border-primary'
                                                                    type='email'
                                                                    name='email'
                                                                    placeholder='Email'
                                                                    value={formData.email}
                                                                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                                                />
                                                                {errors.email && <p className='text-danger ms-3'>{errors.email}</p>}
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6 col-md-6'>
                                                            <div className="text-start mb-3">
                                                                <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='phoneNumber'>Mobile Number</label>
                                                                <input
                                                                    className='form-control border-primary'
                                                                    type='tel'
                                                                    name='phoneNumber'
                                                                    placeholder='Mobile number'
                                                                    value={formData.phoneNumber}
                                                                    onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='border-bottom border-2 border-light-subtle'>
                                                    <span className='poppins30 fw-semibold'>Bank Details</span>
                                                </div>
                                                <div>
                                                    <div className='row mt-1'>
                                                        <div className='col-lg-6 col-md-6'>
                                                            <div className="text-start mb-3">
                                                                <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='bank_Account_type'>Account Type</label> <span className='text-danger fs-4'>*</span>
                                                                <select
                                                                    className='form-control border-primary'
                                                                    type='text'
                                                                    name='bank_Account_type'
                                                                    value={formData.bank_Account_type}
                                                                    onChange={(e) => setFormData({ ...formData, bank_Account_type: e.target.value })}
                                                                >
                                                                    <option value="">Select Account </option>
                                                                    <option value="current_acc">Current Account</option>
                                                                </select>
                                                                {errors.bank_Account_type && <p className='text-danger ms-3'>{errors.bank_Account_type}</p>}
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6 col-md-6'>
                                                            <div className="text-start mb-3">
                                                                <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='beneficiar_name'>Beneficiary Name</label> <span className='text-danger fs-4'>*</span>
                                                                <input
                                                                    className='form-control border-primary'
                                                                    type='beneficiar_name'
                                                                    name='beneficiar_name'
                                                                    placeholder='Enter your Full Name'
                                                                    value={formData.beneficiar_name}
                                                                    onChange={(e) => setFormData({ ...formData, beneficiar_name: e.target.value })}
                                                                />
                                                                {errors.beneficiar_name && <p className='text-danger ms-3'>{errors.beneficiar_name}</p>}
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6 col-md-6'>
                                                            <div className="text-start mb-3">
                                                                <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='bank_account_no'>Account Number</label> <span className='text-danger fs-4'>*</span>
                                                                <input
                                                                    className='form-control border-primary'
                                                                    type='number'
                                                                    name='bank_account_no'
                                                                    placeholder='Enter account number'
                                                                    value={formData.bank_account_no}
                                                                    onChange={(e) => setFormData({ ...formData, bank_account_no: e.target.value })}
                                                                />
                                                                {errors.bank_account_no && <p className='text-danger ms-3'>{errors.bank_account_no}</p>}
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6 col-md-6'>
                                                            <div className="text-start mb-3">
                                                                <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='bank_name'>Bank Name</label> <span className='text-danger fs-4'>*</span>
                                                                <input
                                                                    className='form-control border-primary'
                                                                    type='text'
                                                                    name='bank_name'
                                                                    value={formData.bank_name}
                                                                    onChange={(e) => setFormData({ ...formData, bank_name: e.target.value })}
                                                                />
                                                                {errors.bank_name && <p className='text-danger ms-3'>{errors.bank_name}</p>}
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6 col-md-6'>
                                                            <div className="text-start mb-3">
                                                                <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='IFSC_code'>IFSC</label> <span className='text-danger fs-4'>*</span>
                                                                <input
                                                                    className='form-control border-primary'
                                                                    type='text'
                                                                    name='IFSC_code'
                                                                    placeholder='Enter IFSC'
                                                                    value={formData.IFSC_code}
                                                                    onChange={(e) => setFormData({ ...formData, IFSC_code: e.target.value })}
                                                                />
                                                                {errors.IFSC_code && <p className='text-danger ms-3'>{errors.IFSC_code}</p>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='adminfooterDiv'>
                            <button type='submit' className="btn btn-primary basicnextbtn">Proceed</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default BasicInformation;


