import { useState, useEffect, useRef } from "react";
import { TextField } from "@mui/material";
import { Button } from "react-bootstrap";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import auth from "../../firebaseConfig";
import apiService from "../../services/apiService";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsXCircle } from "react-icons/bs";
import "./LoginPage.css";
import { API_URL } from "../../config";
import axios from "axios";

/* global grecaptcha */

function LoginPage() {
  const [phone, setPhone] = useState("+91");
  const [user, setUser] = useState(null);
  const [otp, setOtp] = useState("");
  const [showOTPField, setShowOTPField] = useState(false);
  const [timer, setTimer] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get('source');
  const recaptchaRef = useRef(null);

  useEffect(() => {
    let countdown;
    if (isResendDisabled && showOTPField) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(countdown);
            setIsResendDisabled(false);
            return 60;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(countdown);
  }, [isResendDisabled, showOTPField]);

  const setupRecaptcha = () => {
    if (!recaptchaRef.current) {
      console.error("reCAPTCHA element not found.");
      return;
    }

    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha', {
        size: "invisible",
        callback: (response) => {
          console.log("Response", response);
        },
        'expired-callback': () => {
          console.log("Recaptcha expired, please try again.");
        }
      });

      window.recaptchaVerifier.render().then((widgetId) => {
        window.recaptchaWidgetId = widgetId;
      });
    } else {
      if (typeof grecaptcha !== 'undefined' && window.recaptchaWidgetId) {
        grecaptcha.reset(window.recaptchaWidgetId);
      }
    }
  };

  const sendOTP = async () => {
    setupRecaptcha();
    const phoneNumber = phone;
    console.log("phoneNumber", phoneNumber);
    const appVerifier = window.recaptchaVerifier;
    try {
      const confirmation = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
      setTimer(60);
      setUser(confirmation);
      setShowOTPField(true);
      setIsResendDisabled(true);
      alert("OTP sent successfully!");
    } catch (err) {
      console.error("Error during OTP send:", err.message);

      if (typeof grecaptcha !== 'undefined' && window.recaptchaWidgetId) {
        grecaptcha.reset(window.recaptchaWidgetId);
      }

      alert(`Failed to send OTP. Error: ${err.message}`);
    }
  };


  const verifyOtp = async (event) => {
    event.preventDefault();
    try {
      console.log("OTP", otp);
      const data = await user.confirm(otp);
      console.log("OTP Data", data);
      if (data) {
        apiService.setData(data.user);
        console.log("data.user.phoneNumber", data.user.phoneNumber)
        const isUserFind = await axios.get(`${API_URL}/vendor/getVendorWithID/${data.user.phoneNumber}`)
        console.log("isUserFind", isUserFind.data)
        const isUser = isUserFind.data
        if (isUser) {
          apiService.setData(isUser);
          alert("Login successful!");
          window.location.href = "/profile";
        } else {
          window.location.href = "/signup";
          alert("User not register!  Please register first.");

        }
      }
    } catch (err) {
      console.error(err);
      alert("Invalid OTP. Please try again.",err);
    }
  };

  return (
    <>
      <div className="login-modal">
        <div className="w-50">
          <div className="login-container shadow">
            {/* <span onClick={onClose}>
              <BsXCircle className="closebtn bg-white rounded-pill" />
            </span> */}
            <h1 className="book">
              Book <span className="your-fs"> Your Front Seat</span>
            </h1>
            <p className="login">Login</p>

            {!showOTPField && (
              <>
                <form>
                  <div className="div-num">
                    <p className="enter-number">Enter your mobile number</p>
                  </div>
                  <div className="mb-5">
                    <input
                      className="form-control mobileinput py-3 border-primary"
                      type="tel"
                      name="mobileNumber"
                      placeholder="+91"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                  </div>
                  <Button onClick={sendOTP} className="btn getotpbtn btn-primary w-50">
                    Get OTP
                  </Button>
                </form>
              </>
            )}

            {showOTPField && (
              <>
                <div className="div-num">
                  <p className="enter-number">Enter OTP</p>
                </div>
                <TextField
                  className="form-control mb-5"
                  onChange={(e) => setOtp(e.target.value)}
                  variant="outlined"
                  placeholder="xxxxxx"
                />
                <br />
                <div>
                  <Button className="btn btn-primary" onClick={verifyOtp}>
                    Login
                  </Button>
                </div>
                <div className="resend-otp">
                  <Link
                    className={`resend-text ${isResendDisabled ? "isResendDisabled" : "isResendEnabled"}`}
                    onClick={sendOTP}
                    disabled={isResendDisabled}
                  >
                    Resend OTP
                  </Link>
                  {timer === 60 ? "" : <span className="countdown"> in {timer} seconds</span>}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div id="recaptcha" ref={recaptchaRef}></div>
    </>
  );
}

export default LoginPage;
