import axios from 'axios';
import React, { useState } from 'react';
import { API_URL } from '../../config';
import { useNavigate } from 'react-router-dom';
import { AiOutlineCloudUpload } from 'react-icons/ai';

function AddOn({ onNext, eventDetail }) {
  const navigate = useNavigate();
  const [additionalData, setAdditionalData] = useState({
    Event_genre: "",
    Event_lang: "",
    Age_restriction: 0,
    Artist: "",
    Instagram_account: "",
    Spotify_account: "",
    Artist_pic: "",
    picture: "",
    custom_info: "",
    Venuepicture: ""
  });
  
  const [fileNames, setFileNames] = useState({
    Artist_pic: "Upload file",
    picture: "Upload file",
    Venuepicture: "Upload file"
  });

  const CustomerInfo = ["info1", "info2", "info3", "info4", "info5"];

  const handleFileSelect = (event, fieldName) => {
    const file = event.target.files[0];
    setAdditionalData({
      ...additionalData,
      [fieldName]: file
    });
    setFileNames({
      ...fileNames,
      [fieldName]: file ? file.name : "Upload file"
    });
  };

  const handleChange = (tab) => {
    onNext(tab);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append('Artist_pic', additionalData.Artist_pic);
      formData.append('picture', additionalData.picture);
      formData.append('Venuepicture', additionalData.Venuepicture);

      const response = await axios.post(`${API_URL}/image/file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        const uploadedArtistPic = response.data.files.Artist_pic[0].filename;
        const uploadedPicture = response.data.files.picture[0].filename;
        const uploadedVenuePicture = response.data.files.Venuepicture[0].filename;

        const resData = await axios.post(`${API_URL}/api/addon`, {
          ...additionalData,
          Artist_pic: uploadedArtistPic,
          picture: uploadedPicture,
          Venuepicture: uploadedVenuePicture
        });

        const data = { addon_id: resData.data.data._id, event_id: eventDetail._id };
        await axios.put(`${API_URL}/api/addeventaddon`, data);

        alert("Data added successfully");
        navigate("/profile");
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      alert("Something went wrong");
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className='addeventdetailDiv mt-3'>
          <div className='container'>
            <div id="AddOn-detail mt-1">
              <div className='row mt-1'>
                <div className='col-lg-6 col-md-6'>
                  <div className="text-start mb-3">
                    <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='Event_genre'>Event Genre</label>
                    <input 
                      className='form-control border-primary'
                      type='text'
                      name='Event_genre'
                      placeholder='Enter Event Genre'
                      value={additionalData.Event_genre}
                      onChange={(e) => setAdditionalData({ ...additionalData, Event_genre: e.target.value })}
                    />
                  </div>
                </div>
                <div className='col-lg-6 col-md-6'>
                  <div className="text-start mb-3">
                    <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='Event_lang'>Event Language</label>
                    <input 
                      className='form-control border-primary'
                      type='text'
                      name='Event_lang'
                      placeholder='Enter Event Language'
                      value={additionalData.Event_lang}
                      onChange={(e) => setAdditionalData({ ...additionalData, Event_lang: e.target.value })}
                    />
                  </div>
                </div>
                <div className='col-lg-6 col-md-6'>
                  <div className="text-start mb-3">
                    <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='Age_restriction'>Age Restriction</label>
                    <input 
                      className='form-control border-primary'
                      type='number'
                      name='Age_restriction'
                      placeholder='Enter age restriction'
                      value={additionalData.Age_restriction}
                      onChange={(e) => setAdditionalData({ ...additionalData, Age_restriction: e.target.value })}
                    />
                  </div>
                </div>
              
                <div className='col-lg-6 col-md-6 mt-1'>
                  <div className="text-start mb-3">
                    <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='Artist'>Artist/ Performer</label>
                    <input 
                      className='form-control border-primary'
                      type='text'
                      name='Artist'
                      placeholder='Enter Artist/ Performer Name'
                      value={additionalData.Artist}
                      onChange={(e) => setAdditionalData({ ...additionalData, Artist: e.target.value })}
                    />
                  </div>
                </div>
                <div className='col-lg-6 col-md-6 mt-1'>
                  <div className="text-start mb-3">
                    <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='Instagram_account'>Artist's Instagram username</label>
                    <input 
                      className='form-control border-primary'
                      type='text'
                      name='Instagram_account'
                      placeholder="Enter Artist's Instagram account"
                      value={additionalData.Instagram_account}
                      onChange={(e) => setAdditionalData({ ...additionalData, Instagram_account: e.target.value })}
                    />
                  </div>
                </div>
                <div className='col-lg-6 col-md-6 mt-1'>
                  <div className="text-start mb-3">
                    <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='Spotify_account'>Artist's Spotify Account</label>
                    <input 
                      className='form-control border-primary'
                      type='text'
                      name='Spotify_account'
                      placeholder="Enter Artist's Spotify Account"
                      value={additionalData.Spotify_account}
                      onChange={(e) => setAdditionalData({ ...additionalData, Spotify_account: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className='row mt-1'>
                <div className='col-lg-6 col-md-6 mt-1'>
                  <div className="text-start mb-3">
                    <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='Artist_pic'>
                      Add Artist Picture
                      <span className='text-danger fs-4'>*</span>
                    </label>
                    <div className="file-input-container">
                      <input
                        type="file"
                        id="Artist_pic"
                        name="Artist_pic"
                        onChange={(e) => handleFileSelect(e, "Artist_pic")}
                      />
                      <label htmlFor="Artist_pic" className="file-input-label border-primary">
                        <span className="upload-text">{fileNames.Artist_pic}</span>
                        <span className="upload-icon text-primary"><AiOutlineCloudUpload size={25} /></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 col-md-6 mt-1'>
                  <div className="text-start mb-3">
                    <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='picture'>
                      Add Picture
                      <span className='text-danger fs-4'>*</span>
                    </label>
                    <div className="file-input-container">
                      <input
                        type="file"
                        id="picture"
                        name="picture"
                        onChange={(e) => handleFileSelect(e, "picture")}
                      />
                      <label htmlFor="picture" className="file-input-label border-primary">
                        <span className="upload-text">{fileNames.picture}</span>
                        <span className="upload-icon text-primary"><AiOutlineCloudUpload size={25} /></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 col-md-6 mt-1'>
                  <div className="text-start mb-3">
                    <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='Venuepicture'>
                      Add Venue Layout
                      <span className='text-danger fs-4'>*</span>
                    </label>
                    <div className="file-input-container">
                      <input
                        type="file"
                        id="Venuepicture"
                        name="Venuepicture"
                        onChange={(e) => handleFileSelect(e, "Venuepicture")}
                      />
                      <label htmlFor="Venuepicture" className="file-input-label border-primary">
                        <span className="upload-text">{fileNames.Venuepicture}</span>
                        <span className="upload-icon text-primary"><AiOutlineCloudUpload size={25} /></span>
                      </label> 
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 col-md-6 mt-1'>
                  <div className="text-start mb-3">
                    <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='custom_info'>Add Custom Information</label>
                    <span className='text-danger fs-4'>*</span>
                    <select 
                      className='form-control border-primary' 
                      name='custom_info' 
                      placeholder='Enter Event Category'
                      value={additionalData.custom_info}
                      onChange={(e) => setAdditionalData({ ...additionalData, custom_info: e.target.value })}
                    >
                      <option value='default' disabled>Select</option>
                      {CustomerInfo.map((info, index) => (
                        <option value={info} key={index}>{info}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='adminfooterDiv text-end py-3 mt-5'>
          <button type='button' className={`btn backbtn`} onClick={() => handleChange("ticket-detail")}>Back</button>
          <button type='submit' className='btn basicnextbtn'>Save</button>
        </div>
      </form>
    </div>
  );
}

export default AddOn;
