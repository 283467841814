import React from 'react'
import { API_URL } from '../../config';
import axios from 'axios';
import apiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
const Agreement = ({vendorBasicDetail, vendorDocument}) => {
    const navigate = useNavigate();


    const handleSubmitForm = async (e)=>{
        e.preventDefault();
        try {
            const formData = {
                vendorBasicDetail: vendorBasicDetail, 
                vendorDocumen: vendorDocument
            }
            if(formData){
                const response = await axios.post(`${API_URL}/vendor/addvendor`, formData)
                if(response.status===200){
                    apiService.setData(formData);
                    alert("Vendor registered successfull!")
                    navigate("/profile");
                }else{
                    alert("Error! please try again")
                }
            }
        } catch (error) {
            alert("Something went wrong please try again")
        }
    }

    return (
        <>
            <form onSubmit={handleSubmitForm}>
                <div id="basics-detail">
                    <div className='addeventdetailDiv'>
                        <div className='container'>
                            <div className='container'>
                                <div className='mt-lg-5 mt-md-5'>
                                    <div className='text-center'>
                                        <span className='poppins30 fw-semibold'>Sign Agreement</span>
                                    </div>
                                    <div>
                                        <ol>
                                            <li>
                                                <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eum tempore consectetur minima ex voluptate nesciunt fugiat est possimus, laboriosam nobis, facere aliquid consequuntur, ea tenetur illum vitae saepe odio quis?</span>
                                            </li>
                                            <li>
                                                <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eum tempore consectetur minima ex voluptate nesciunt fugiat est possimus, laboriosam nobis, facere aliquid consequuntur, ea tenetur illum vitae saepe odio quis?</span>
                                            </li>
                                            <li>
                                                <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eum tempore consectetur minima ex voluptate nesciunt fugiat est possimus, laboriosam nobis, facere aliquid consequuntur, ea tenetur illum vitae saepe odio quis?</span>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='adminfooterDiv'>
                    <button type='submit' className="btn btn-primary basicnextbtn">Sign Agreement</button>
                </div>
            </form>
        </>
    )
}

export default Agreement
