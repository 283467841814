import { useState } from "react";
import './AccountSetUp.css'
import NavigationBar from "../Components/NavigationBar";
import { BsRecordCircleFill, BsCheckCircleFill, BsCircle, BsCircleFill } from 'react-icons/bs';
import BasicInformation from "../Components/Basic_info";
import Upload_Document from "../Components/Upload_Document";
import Agreement from "../Components/Agreement";

const AccountSetUp = () => {
    const [isActive, setIsActive] = useState({
        tab: "basic_info"
    });
    const [vendorBasicDetail, SetVendorBasicDetail] = useState({})
    const [vendorDocument, SetVendorDocument] = useState({})
    const [validTabs, setValidTabs] = useState({
        "basic_info": false,
        "upload_doc": false,
        "agreement": false
    });

    const handleSaveBasicInfo = (Nexttab,isValid, basicInfoDetail) => {
        setValidTabs({ ...validTabs, [isActive.tab]: isValid });
        setIsActive({ ...isActive, tab: Nexttab });
        if (basicInfoDetail) {
            SetVendorBasicDetail( basicInfoDetail);
        }
    }

    const handleSaveDocument = (Nexttab, isValid, documents) => {
        setValidTabs({ ...validTabs, [isActive.tab]: isValid });
        setIsActive({ ...isActive, tab: Nexttab });
        if (documents) {
            SetVendorDocument(documents);
        }
    }

    const ActiveTab = (tab) => {
        const tabsOrder = ["basic_info", "upload_doc", "agreement"];
        const currentIndex = tabsOrder.indexOf(isActive.tab);
        const nextIndex = tabsOrder.indexOf(tab);

        if (nextIndex <= currentIndex + 1 && validTabs[tabsOrder[currentIndex]]) {
            setIsActive({ ...isActive, tab: tab });
        } else {
            alert("Please Fill Form");
        }
    }

    const renderIcon = (tab) => {
        const tabsOrder = ["basic_info", "upload_doc", "agreement"];
        const currentIndex = tabsOrder.indexOf(isActive.tab);
        const tabIndex = tabsOrder.indexOf(tab);

        if (tabIndex > currentIndex) {
            return <BsCircleFill className='ms-2 text-light' />;
        } else if (tab === isActive.tab) {
            return <BsRecordCircleFill className='ms-2 text-primary bg-white rounded-5' />;
        } else if (validTabs[tab]) {
            const element = document.getElementById(tab);
            if (element) {
                element.style.cssText = "color: black; background-color: #bfdaf1 !important;";
            }
            return <BsCheckCircleFill className='ms-2 text-primary' />;
        }
    }

    return (
        <>
            <NavigationBar />
            <div className='container-main'>
                <div className="d-flex flex-column">
                    <span className='main-heading text-start'>Account Setup</span>
                    <span className='sub-heading'>Please fill in the below details to register yourselves (one time activity) to host your event.</span>
                </div>
                <div className='d-flex justify-content-between mt-3'>
                    <button
                        className={`fw-semibold ${isActive.tab === "basic_info" ? "isActive" : "tabheading"}`}
                        id="basic_info"
                        onClick={() => ActiveTab("basic_info")}
                    >   {renderIcon("basic_info")}&nbsp;
                        Basic Information
                    </button>
                    <button
                        className={`fw-semibold  ${isActive.tab === "upload_doc" ? "isActive" : "tabheading"}`}
                        id="upload_doc"
                        onClick={() => ActiveTab("upload_doc")}
                        disabled={!validTabs["basic_info"]}
                    >   {renderIcon("upload_doc")}&nbsp;
                        Upload Document
                    </button>
                    <button
                        className={`fw-semibold  ${isActive.tab === "agreement" ? "isActive" : "tabheading"}`}
                        id="agreement"
                        onClick={() => ActiveTab("agreement")}
                        disabled={!validTabs["upload_doc"]}
                    >   {renderIcon("agreement")}&nbsp;
                        Agreement
                    </button>
                </div>
            </div>
            {
                (isActive.tab === "upload_doc") ? 
                    <Upload_Document onNext={handleSaveDocument} /> :
                (isActive.tab === "agreement") ? 
                    <Agreement onNext={(nextTab) => setIsActive({ ...isActive, tab: nextTab })} vendorBasicDetail={vendorBasicDetail} vendorDocument={vendorDocument}/> : 
                <BasicInformation onNext={handleSaveBasicInfo} />
            }
        </>
    )
}

export default AccountSetUp;